import React from "react";
import Typography from "components/Typography";

function LegalTextBlock({ children }) {
  return (
    <Typography variant="body1" className="py-2">
      {children}
    </Typography>
  );
}

export default LegalTextBlock;
