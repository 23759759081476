import React from "react";

const tagMapping = {
  title: "p",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  subtitle1: "p",
  caption1: "p",
  caption2: "p",
  body1: "p",
  body2: "p",
  bodySmall: "p",
};

// Captions use p tag and additional css class to set different styles
const tagDefaultClass = {
  title: "title",
  subtitle1: "subtitle-1",
  body1: "body-1",
  body2: "body-2",
  bodySmall: "body-small",
  caption1: "caption-1",
  caption2: "caption-2",
};

function Typography({
  variant = "body1",
  align,
  children,
  className,
  ...other
}) {
  const CustomTag = tagMapping[variant];
  const defaultClass = tagDefaultClass[variant];
  return (
    <CustomTag className={[defaultClass, className].join(" ")} {...other}>
      {children}
    </CustomTag>
  );
}

export default Typography;
