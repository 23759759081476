import React from "react";
import Typography from "components/Typography";

export default function LegalHeading3({ children }) {
  return (
    <Typography variant="h3" className="mt-2">
      {children}
    </Typography>
  );
}
