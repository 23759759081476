import React from "react";
import Typography from "components/Typography";

export default function LegalHeading2({ children }) {
  return (
    <Typography variant="h2" className="mb-8">
      {children}
    </Typography>
  );
}
