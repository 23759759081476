import React from "react";
import LegalTextBlock from "../components/LegalTextBlock";
import LegalHeading3 from "../components/LegalHeading3";
import LegalHeading2 from "../components/LegalHeading2";
import LegalList from "../components/LegalList";

function cookie(props) {
  return (
    <div className="py-10 max-w-5xl mx-auto text-left">
      <LegalHeading2>Privacy policy regarding cookies</LegalHeading2>

      <LegalHeading3>1. Introduction</LegalHeading3>
      <LegalTextBlock>
        When you visit our website or mobile application, we collect information
        about your browser, IP address, and so on, in particular in order to
        make the use of our website or mobile application as efficient and
        comfortable as possible and with a view to protecting our computer
        system from attacks and other illegal activities.
      </LegalTextBlock>
      <LegalTextBlock>
        This information is collected in accordance with applicable data
        protection legislation, including the General Data Protection Regulation
        (Regulation 2016/679) of May 25, 2018 ("<strong>GDPR</strong>") and
        Directive 2002/58/EC on Privacy and Electronic Communications ("
        <strong>ePrivacy Directive</strong>").
      </LegalTextBlock>
      <LegalHeading3>2. To whom is this policy addressed?</LegalHeading3>
      <LegalTextBlock>
        This Policy applies to all visitors to our website and mobile
        application.
      </LegalTextBlock>

      <LegalHeading3>3. What exactly are Cookies ?</LegalHeading3>
      <LegalTextBlock>
        3.1. In order to collect the information described in this Policy, we
        use Cookies on our website and on our mobile application.
      </LegalTextBlock>

      <LegalTextBlock>
        3.2. A Cookie is a small text file that a website transfers to your
        computer's hard drive through your web browser to enable the website to
        recognize your browser and remember certain information.{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        Cookies do not contain any information that would allow us to contact
        you by phone, email or post.{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        3.3. Cookies can be first-party or third-party Cookies:{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <LegalList>
          <li>
            <u>First-party Cookies</u>: Cookies that the website you are
            visiting places on your computer;
          </li>
          <li>
            <u>Third-party Cookies</u>: Cookies placed on your computer through
            the website, but by third-parties, such as Google.
          </li>
        </LegalList>
      </LegalTextBlock>

      <LegalTextBlock>
        3.4. Cookies can be session cookies or permanent Cookies:
      </LegalTextBlock>

      <LegalTextBlock>
        <LegalList>
          <li>
            <u>Session Cookies</u>: cookies that are automatically deleted when
            the user closes the browser;
          </li>
          <li>
            <u>Permanent Cookies</u>: Cookies that remain stored on the user's
            terminal equipment until a predefined expiration date (which can be
            expressed in minutes, days or years).
          </li>
        </LegalList>
      </LegalTextBlock>

      <LegalTextBlock>
        3.5. Cookies can be functional or non-functional Cookies:
      </LegalTextBlock>

      <LegalTextBlock>
        <LegalList>
          <li>
            <u>Functional Cookies</u>: Cookies either (1) absolutely necessary
            to provide a service expressly requested by the Internet user or (2)
            absolutely necessary to carry out the sending of a communication via
            an electronic communications network (e.g. indications necessary for
            a transaction or performance cookies analyzed anonymously). They do
            not require the consent of the person concerned;
          </li>
          <li>
            <u>Non-functional Cookies</u>: Cookies other than functional that
            require the consent of the person concerned.
          </li>
        </LegalList>
      </LegalTextBlock>

      <LegalHeading3>
        4. What Cookies does our website use and how long do they work?
      </LegalHeading3>

      <LegalTextBlock>We use the following cookies:</LegalTextBlock>

      <LegalTextBlock>
        <u>Functional Cookies</u>
      </LegalTextBlock>

      <LegalTextBlock>
        Our website does not use functional cookies.
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Non-functional Cookies</u>
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>
          Splitbee's internal Cookies for the website (persistent Cookies):
        </strong>{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Description:</u> These Cookies are used to measure the audience of
        our website and to generate reports about visitors' interactions with
        our website. These Cookies are also used to store information that does
        not personally identify you. These are third-party cookies.
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Duration of operation:</u> Indefinite, until consent is revoked.
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Retention period of collected data:</u> Indefinite, until consent is
        revoked.
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Statistical analysis cookies for the mobile application</strong>{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Description:</u> These Cookies allow us to know the behavior of the
        users of the mobile application, their preferences, and so on. The data
        collected is anonymized. We have no way to link the data to a specific
        user.{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Duration of operation:</u> Indefinite, until consent is revoked.
      </LegalTextBlock>

      <LegalTextBlock>
        <u>Retention period of collected data:</u> Indefinite, until consent is
        revoked.
      </LegalTextBlock>

      <LegalHeading3>
        5. What is the purpose and legal basis of the processing?
      </LegalHeading3>

      <LegalTextBlock>
        5.1. We process Functional Cookies for the fulfillment of our legitimate
        interests (Article 6 (1)(f) GDPR), in particular for the following
        purposes:{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <LegalList>
          <li>
            measuring and optimizing the performance of our website in order to
            make it more user-friendly;
          </li>
          <li>
            to provide an efficient and appropriate interface to our website
            visitors;
          </li>
          <li>
            to use your Personal Data in aggregate or anonymized models for
            statistical analysis;
          </li>
        </LegalList>
      </LegalTextBlock>

      <LegalTextBlock>
        In the above cases, we always strive to maintain a fair balance between
        the need to process your Personal Data and the preservation of your
        rights and freedoms, including your privacy.
      </LegalTextBlock>

      <LegalTextBlock>
        5.2. We process non-functional Cookies with your consent (Article 6
        (1)(a) GDPR), in particular for the following purposes:{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <LegalList>
          <li>
            to evaluate and optimize the quality of our online services and the
            ease of use of our website in order to provide you with the best
            possible experience;
          </li>
          <li>
            to establish customer or prospect segments or marketing profiles,
            for example for targeted marketing;
          </li>
        </LegalList>
      </LegalTextBlock>

      <LegalHeading3>6. Your rights to accept and manage Cookies</LegalHeading3>

      <LegalTextBlock>
        When you visit our website, a pop-up message appears to notify you of
        the use of Cookies. When using our website, you have the choice to
        accept or decline Cookies on a granular basis, with the exception of
        functional Cookies that are necessary and cannot be modified.
      </LegalTextBlock>

      <LegalTextBlock>
        The registration of a Cookie on your navigation terminal is subject to
        your will. You have the choice to accept or refuse the non-functional
        Cookies of the website www.ooofer.com.
      </LegalTextBlock>

      <LegalTextBlock>
        In any case, you can at any time configure your browser to inform you
        when a "Cookie" is created or to prevent or systematically accept their
        recording.{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        Regarding the management of Cookies and your choices, the configuration
        of each browser (Google Chrome, Firefox, Internet Explorer, Safari and
        Opera) is different. It is described in the help menu of your browser,
        which will allow you to know how to modify your wishes regarding
        Cookies.
      </LegalTextBlock>

      <LegalTextBlock>
        If you or another user of your device wishes to withdraw your consent at
        any time, you can do so by changing your browser settings:
        <LegalList>
          <li>
            Google Chrome: https://support.google.com/chrome/answer/95647?hl=en;
          </li>
          <li>
            Firefox:
            https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=activer-desactiver-cookies-preferences&redirectlocale=fr;
          </li>
          <li>
            Internet Explorer:
            https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d;
          </li>
          <li>Safari (iOS): https://support.apple.com/en-gb/HT201265;</li>
          <li>
            Safari (MacOS):
            https://support.apple.com/en-gb/guide/safari/sfri11471/mac.
          </li>
        </LegalList>
      </LegalTextBlock>

      <LegalHeading3>
        7. Do any third-parties have access to Cookies?
      </LegalHeading3>
      <LegalTextBlock>
        No third-parties, other than our contractors who act on our behalf, have
        access to Cookies.
      </LegalTextBlock>

      <LegalHeading3>
        8. Your rights in relation to your Personal Data
      </LegalHeading3>

      <LegalTextBlock>
        As a data subject and as also indicated in the Privacy Policy, you may
        exercise the following rights with respect to your Personal Data by
        contacting us at the following email address: info[at]ooofer.com.{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Right of access:</strong>
      </LegalTextBlock>

      <LegalTextBlock>
        Where permitted by law, you may obtain confirmation that we are
        processing your personal data and, if so, you may be given access to it.
        Any request for access will only be granted if we can identify you with
        certainty on the basis of the information in our possession.
      </LegalTextBlock>

      <LegalTextBlock>
        Notwithstanding the above, any request for access to your data must be
        accompanied by a copy of your identity card.
      </LegalTextBlock>

      <LegalTextBlock>
        We have 30 days to respond to your request, this period only starting
        from the moment we are in possession of all the information required to
        satisfy your request.
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Right of rectification:</strong>
      </LegalTextBlock>

      <LegalTextBlock>
        If applicable, you may request that we rectify any incomplete or
        inaccurate data about you, free of charge.
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Right to withdraw your consent:</strong>
      </LegalTextBlock>

      <LegalTextBlock>
        As indicated in the aforementioned Article 6, you may withdraw your
        consent to Cookies at any time, it being specified that the withdrawal
        of your consent will not affect the lawfulness of processing carried out
        prior to such withdrawal, or processing deriving from any other basis of
        legitimacy than consent.
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Right to erasure:</strong>
      </LegalTextBlock>

      <LegalTextBlock>
        You have the right to request the deletion, as soon as possible, of your
        personal data, when:
      </LegalTextBlock>

      <LegalTextBlock>
        <LegalList>
          <li>
            your data is no longer necessary for the purposes for which it was
            collected and its retention is not or no longer required;
          </li>
          <li>
            you withdraw your consent for Cookies and there is no other legal
            basis for the processing;
          </li>
          <li>
            your data must be deleted in order to comply with a legal obligation
            under EU law or the law of the Member State to which we are subject;
          </li>
          <li>your data has been processed unlawfully by us;</li>
        </LegalList>
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Right to object:</strong>
      </LegalTextBlock>

      <LegalTextBlock>
        You always have the right to object, without giving reasons and free of
        charge, to the use of your personal data for direct marketing purposes.{" "}
      </LegalTextBlock>

      <LegalTextBlock>
        <strong>Recourse in the event of a complaint:</strong>
      </LegalTextBlock>

      <LegalTextBlock>
        If you believe that your rights in relation to the processing of your
        personal data have not been respected, you can submit a complaint to the
        following e-mail address: info[at]ooofer.com or, if this is not dealt
        with satisfactorily, to the competent supervisory authority (e.g. "Data
        Protection Authority").
      </LegalTextBlock>

      <LegalHeading3>9. Linked websites</LegalHeading3>
      <LegalTextBlock>
        We are not responsible for the privacy policies and practices of other
        websites, even if you have accessed a third-party website through links
        that may be present on our website. We recommend that you check the
        policy of each website you visit and contact the owner or operator of
        that website if you have any concerns or questions.
      </LegalTextBlock>

      <LegalHeading3>10. Changes to this Policy</LegalHeading3>
      <LegalTextBlock>
        We reserve the right to amend or modify this Policy without notice and
        if we do so, we will post the changes on this page. It is your
        responsibility to check the Policy each time you visit our website.{" "}
      </LegalTextBlock>
    </div>
  );
}

export default cookie;
